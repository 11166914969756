import * as Sentry from "@sentry/nuxt";

Sentry.init({
    dsn: process.env.SENTRY_DSN || '',
        config: {
        environment: process.env.INTELETECH_ENVIRONMENT || 'dev',
            tracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE || '0',
    },
    sourceMapsUploadOptions: {
        org: "inteletech",
            project: process.env.SENTRY_SOURCEMAP_PROJECT,
            authToken: process.env.SENTRY_SOURCEMAP_AUTHTOKEN,
    },
});
